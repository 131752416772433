<script>
import CheckboxLine from './CheckboxLine'

export default {
  functional: true,
  props: {
    data: {
      type: Object
    },
    name: String,
    locked: Boolean,
    value: Boolean
  },
  render (h, context) {
    const CellWrapper = content => h('div', {
      class: 'cell-wrapper'
    }, content)

    return [
      CellWrapper([
        h(CheckboxLine, {
          props: context.props,
          on: context.listeners
        })
      ]),
      CellWrapper([
        h('div')
      ]),
      CellWrapper([
        h('div')
      ])
    ]
  }
}
</script>

<style lang="scss" scoped>
.cell-wrapper {
  padding: 0.125em 0.5em;
  display: flex;
  align-items: center;
}
</style>
